import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApplicationsService} from 'src/app/applications/applications.service';
import {CoreComponent} from 'src/app/core/core.component';
import {ContactCenterService} from '../../../contact-center.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {ActivatedRoute, Router} from '@angular/router';
import {Application} from 'src/app/applications/application/application';
import {NgForm} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {UsersService} from '../../../../../users/users.service';
import {User} from '../../../../users/user/user';
declare var $: any;
@Component({
  selector: 'app-application-histroy',
  templateUrl: './application-histroy.component.html',
  styleUrls: ['./application-histroy.component.scss']
})
export class ApplicationHistroyComponent extends CoreComponent<Application> {

  @Input() status: string;
  user: User = new User();
  public validated: boolean;
  @Input() public application: Application;
  @ViewChild('ngForm', {static: false}) public ngForm: NgForm;

  constructor(public modalService: NgbModal, public servicesService: ApplicationsService, public contactCenterService: ContactCenterService,private router: Router,
              public route: ActivatedRoute, private matSnackBar: MatSnackBar,
              protected gaService: GoogleAnalyticsService,public activeModal: NgbActiveModal,
              public userServices: UsersService
  ) {
    super(servicesService, route);
  }

  ngOnInit(): void {

    this.blockUI.start();
    this.userServices.getProfile().subscribe((user: User) => {
      this.user = user;
      this.blockUI.stop();
    }, (error: HttpErrorResponse) => {
      this.gaService.exception(error.error?.message, true);
      this.blockUI.stop();
      if (error.status === 401) {
        this.activeModal.close();
        this.router.navigate(['/']);
        this.matSnackBar.open('Your session has expired', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });
  }
  getStatus(status: string) {

    if(status ==='NotStarted') {
      return 'Not Submitted';
    }
    if(status ==='AwaitingEvaluation'){
      return 'Verification';
    }
    if(status == 'ProvisionallyFunded'){
      return 'Provisionally Funded';
    }
    if(status === 'Filtering' || status ==='Validation'){
      return 'Application in progress';
    }
    if(status === 'ApplicationInProgress') {
      return 'Requesting financial and academic data';
    }
    return status;

  }


  onSubmit() {
    if (!this.ngForm.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {
      this.blockUI.start();
      this.servicesService.patch(this.application).subscribe((application: Application) => {
        this.application = application;
        this.blockUI.stop();
        this.matSnackBar.open('Disability status has been updated', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }, (error: HttpErrorResponse) => {
        this.gaService.exception(error.error?.message, true);
        this.blockUI.stop();
        if (error.status === 401) {
          this.activeModal.close();
          this.router.navigate(['/']);
          this.matSnackBar.open('Your session has expired', 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
    }
  }

}
